import React from 'react';

import Header from './components/header/Header';
import Main from './components/mainPart/MainPart';
import AboutPart from './components/aboutPart/AboutPart';
import TransferPart from './components/transferPart/TransferPart';
import CommissionPart from './components/commissionPart/CommissionPart';
import QuestionsPart from './components/questionsPart/QuestionsPart';
import ContactsPart from './components/contactsPart/ContactsPart';
import Footer from './components/footer/Footer';

class App extends React.Component {
    render() {
        return (
            <div>
                <Header/>
                <Main/>
                <AboutPart/>
                <TransferPart/>
                <CommissionPart/>
                <QuestionsPart/>
                <ContactsPart/>
                <Footer/>
            </div>
        )
    }
}

export default App;
