import React from 'react';
import TextField from '@material-ui/core/TextField';

import './TextInput.scss';
import {ReactComponent as Copy} from '../../resources/icons/icon-copy.svg';
import {IconButton} from "@material-ui/core";

class TextInput extends React.Component {

    handleCopy = () => {
        navigator.clipboard.writeText(this.props.value);
    }

    render() {
        const {withCopy, className, ...textFieldProps} = this.props;
        return (
            <div className={`text-input ${className ? className : ''}`}>
                <TextField
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{className: 'label'}}
                    {...textFieldProps}
                    className={textFieldProps.label ? '' : 'without-tooltip'}
                />
                {withCopy
                    ?
                    <IconButton onClick={this.handleCopy}><Copy/></IconButton>
                    : ''}
            </div>
        )
    }
}

export default TextInput;
