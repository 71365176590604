export const PAYOUT_STATUS = {
    PENDING: 'PENDING',
    WAITING_3DS: 'WAITING_3DS',
    TRANSFERED: 'TRANSFERED',
    WAITING_CONTINUE_TRANSFER: 'WAITING_CONTINUE_TRANSFER',
    FAILED_CONTINUE_TRANSFER: 'FAILED_CONTINUE_TRANSFER',
    FAILED: 'FAILED',
};

export const RESULT_STATUS = {
    WAIT: 'wait',
    SUCCESS: 'success',
    ERROR: 'warning',
    CODE: 'code',
}