import React from 'react';
import {Button, Menu, MenuItem} from '@material-ui/core';
import WithTranslator from '../../providers/WithTranslator';

import './Header.scss';
import rus from '../../resources/rus.svg';
import eng from '../../resources/eng.svg';
import {ReactComponent as ArrowDown} from '../../resources/arrow-down.svg';

class LanguageControl extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            langAnchor: null
        }
    }

    setLanguage = lang => {
        this.setState({langAnchor: null});
        this.props.setLanguage(lang);
    }

    render() {
        return (
            <>
                <Button className='lang-button'
                        onClick={e => this.setState({langAnchor: e.currentTarget})}>
                    {this.props.mobile && <span className='title'>{this.props.translator('header.lang.title')}</span>}
                    {this.props.language === 'ru'
                        ? <img src={rus} alt='rus'/>
                        : <img src={eng} alt='eng'/>
                    }
                    <ArrowDown/>
                </Button>
                <Menu
                    className='lang-menu'
                    anchorEl={this.state.langAnchor}
                    open={!!this.state.langAnchor}
                    onClose={() => this.setState({langAnchor: null})}
                    keepMounted
                >
                    <MenuItem onClick={() => this.setLanguage(this.props.language)}>
                        <img src={this.props.language === 'ru' ? rus : eng}
                             alt={this.props.language === 'ru' ? 'rus' : 'eng'}/>
                        {this.props.language === 'ru' ? 'РУС' : 'ENG'}
                    </MenuItem>
                    <MenuItem onClick={() => this.setLanguage(this.props.language === 'ru' ? 'en' : 'ru')}>
                        <img src={this.props.language === 'ru' ? eng : rus}
                             alt={this.props.language === 'ru' ? 'eng' : 'rus'}/>
                        {this.props.language === 'ru' ? 'ENG' : 'РУС'}
                    </MenuItem>
                </Menu>
            </>
        )
    }
}

export default WithTranslator(LanguageControl);
