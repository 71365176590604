import React from 'react';
import {Tooltip as MUTooltip, ClickAwayListener} from '@material-ui/core';

import './Tooltip.scss';

export default function (props) {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => setOpen(false);

    const handleTooltipOpen = () => setOpen(true);

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <MUTooltip
                // onClick={handleTooltipOpen}
                // onClose={handleTooltipClose}
                // open={open}
                {...props}
            >
                {props.children}
            </MUTooltip>
        </ClickAwayListener>
    );
}
