import React from 'react';
import {BehaviorSubject} from 'rxjs';

import enMessages from '../resources/en';
import ruMessages from '../resources/ru';

const locales = {en: enMessages, ru: ruMessages}
const DEFAULT_LANGUAGE = 'ru';

let language = localStorage.getItem('language') || DEFAULT_LANGUAGE;
let translations = locales[language];

const translations$ = new BehaviorSubject(translations);

const setLanguage = _language => {
    language = _language;
    localStorage.setItem('language', language);
    translations$.next(locales[language]);
};

export default function (WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props);

            this.state = {translations};
        }

        componentDidMount() {
            this.subscription = translations$.subscribe(translations =>
                this.setState({translations})
            );
        }

        componentWillUnmount() {
            this.subscription.unsubscribe();
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    translator={id => this.state.translations[id] || id}
                    language={language}
                    setLanguage={setLanguage}
                />
            );
        }
    };
}