import React from 'react';

export default class TransferFreeDS extends React.Component {
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props && this.props._3ds) {
            if (this.props._3ds.method.toLowerCase() === "post") {
                document.forms['3ds'].submit();
            } else {
                window.open(this.props._3ds.uri,"_self");
            }
        }
    }

    render() {
        if (!this.props._3ds) return (<></>)

        let {method, uri, params} = this.props._3ds;
        params = Object.fromEntries(new URLSearchParams(params));
        return (
            <form action={uri} method={method.toLowerCase()} name='3ds'>
                {Object.entries(params).map(([n, v], i) =>
                    <input type='hidden' key={n} name={n} value={v}/>
                )}
            </form>
        )
    }
}